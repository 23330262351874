export const FOOTER_SEO_CONTENT_CANCERCROWDFUNDING = {
  mainTitle: 'How Cancer Crowdfunding can help you pay for High cost Treatment?',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'Start A Cancer Crowdfunding Campaign On Ketto And Provide Financial Help For Cancer Patients In India.',
      desc: `With Ketto Cancer Crowdfunding, You Never Have To Worry About Rising Cancer Treatment Costs & Expensive Hospital Bills.`,
      children: [
        {
          id: 1,
          heading: 'What Is Cancer?',
          desc: '“Cancer” is a blanket term given to a collection of linked diseases characterised by the formation of abnormal cells.',
          list: null
        },
        {
          id: 2,
          heading: 'What is the cause of cancer?',
          desc: `<p class="desc"> In our body, new cells replace the old ones, which die or wither as a part of a naturally occurring process. Every single cell follows this defined cell cycle, thus sustaining the internal equilibrium which keeps us fit. <br>
                    Cancer occurs when, due to some unique gene mutations, this well defined orderly process is altered. As a result, the cells stop following the instructions of genes. It begins to grow and divide uncontrollably rather than dying when it should.</p>`,
          list: null
        },
        {
          id: 3,
          heading: 'What is cancer crowdfunding?',
          desc: `An online crowdfunding campaign that collects monetary donations to assist a cancer patient or the family of a cancer patient to cover expensive cancer treatments and chemotherapy is called cancer crowdfunding.`,
          list: null
        },
        {
          id: 4,
          heading: 'How can you benefit from starting an online cancer crowdfunding campaign on ketto?',
          desc: `<p class="desc"> The treatment costs for cancer are very high. Eventually, families and relatives of cancer patients are overburdened by the rising financial constraints to <a href="https://www.ketto.org/blog/cant-afford-your-medical-bill-heres-what-you-can-do">pay expensive medical and treatment bills.</a>
                    Crowdfunding to raise funds for cancer treatment expenses and chemotherapy drugs is the most cost-effective and easy way to begin. You can start an individual cancer fundraising campaign or <a href="https://www.ketto.org/blog/successful-way-to-raise-money-for-medical-bills">raise funds</a> for a nonprofit serving individuals affected by cancer.</p>`,
          list: null
        },
        {
          id: 5,
          heading: 'What are the types of cancer that you can raise funds for?',
          desc: `On ketto, you can raise funds for breast cancer, lung cancer, prostate cancer, throat cancer and any other cancer that you want to raise funds for.`,
          list: null
        },
        {
          id: 6,
          heading: 'What are the different types of cancers?',
          desc: `<p class="desc"> There are more than 200 types of cancers.<br>
                    Cancers are classified according to where they start in the human body, such as breast cancer or lung cancer.</p>`,
          list: null
        },
      ]
    },
    {
      id: 2,
      heading: '',
      subHeading: 'Cancers can also be grouped according to the type of cell they begin in. There are five main groups',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'Carcinoma',
          desc: `It begins in the skin or tissues lining or cover internal organs. Carcinoma has subtypes, these include:`,
          list: [
            {
              id: 1,
              title: 'adenocarcinoma',
              desc: '',
              list: null
            },
            {
              id: 2,
              title: 'basal cell carcinoma',
              desc: '',
              list: null
            },
            {
              id: 3,
              title: 'squamous cell carcinoma and',
              desc: '',
              list: null
            },
            {
              id: 4,
              title: 'transitional cell carcinoma',
              desc: '',
              list: null
            }
          ]
        },
        {
          id: 2,
          heading: 'Sarcoma',
          desc: `This begins in the connective or supportive tissues like bones, cartilage, fats, muscles or blood vessels`,
          list: null
        },
        {
          id: 3,
          heading: 'Leukaemia (Blood Cancer)',
          desc: `This cancer is found in white blood cells. It originates in tissues that make blood cells, such as bone marrow.`,
          list: null
        },
        {
          id: 4,
          heading: 'Lymphoma and myeloma',
          desc: `<p class="desc"> These cancers begin in the cells of our immune system <br>
                    brain and spinal cord cancers – these are also called central nervous system cancers</p>`,
          list: null
        },
      ]
    },
    {
      id: 3,
      heading: '',
      subHeading: 'Why choose Ketto’s cancer crowdfunding?',
      desc: `<p class="desc">The Cost Of Treatment for Cancer is exceptionally high. <br>
            Cancer occurs without warning. A middle-class family's health insurance and savings are not enough to cover the expensive treatment costs of cancer and its related procedures. Funding for cancer through crowdfunding for cancer patients on Ketto helps you pay these expensive bills by receiving small donations from a large group of generous people in India and worldwide.</p>`,
      children: null
    },
    {
      id: 4,
      heading: '',
      subHeading: 'Asking For Money to pay for cancer medical bills Isn’t Easy.',
      desc: `<p class="desc">Asking for help and describing financial problems is awkward and inconvenient. Online cancer crowdfunding eases the process and makes it seamless. When your near and dear ones are diagnosed with life-threatening diseases like cancer, it’s overwhelming emotionally and financially. Such emergencies require medical treatments to start immediately. </p>
            <p class="desc"> Ketto provides a platform to share your emergency; <a href="https://www.ketto.org/crowdfunding">online crowdfunding </a> helps you gather funds quickly.</p>`,
      children: null
    },
    {
      id: 5,
      heading: '',
      subHeading: 'Loan Repayment Is Extremely Stressful.',
      desc: `Personal <a href="https://www.ketto.org/medical-finance-loans"> loans </a> are provided at very high-interest rates and require the borrower to repay the money in a stipulated time frame. Repaying this loan may take months, sometimes years. Online crowdfunding comes without debts, and the Money need not be returned.`,
      children: [
        {
          id: 1,
          heading: 'Start A Cancer Crowdfunding Campaign On Ketto Today!',
          desc: '',
          list: null
        },
      ]
    },
  ],
  searches: {
    data: `<a href="https://www.ketto.org/cause/medical-fundraising">Medical Crowdfunding </a> |
        <a href="https://covid19.ketto.org/"> Coronavirus Relief Fund </a> |
        <a href="https://www.ketto.org/campaign/crowdfunding-for-education.php"> Education Crowdfunding </a>|
        <a href="https://www.ketto.org/how_it_works/fundraising-ideas-for-ngos.php">NGO Fundraising</a> |
        <a href="https://www.ketto.org/blog/social-impact-plan-from-ketto-is-taking-fundraising-to-the-next-level"> Social Impact Plan (SIP) </a>|
        <a href="https://www.ketto.org/blog/what-is-health-nest"> What Is Health Nest? </a> |
        <a href="https://www.ketto.org/blog/individual-fundraising-ideas-meaning"> How to Raise Funds </a>|
        <a href="https://www.ketto.org/blog/what-are-the-types-of-crowdfunding-in-india">Types of Crowdfunding </a> |
        <a href="https://www.ketto.org/cause/cancer-crowdfunding"> Raise Donation for Cancer Treatment </a> |
        <a href="https://www.ketto.org/blog/cancer-crowdfunding-5-ways-to-raise-money-for-kids-with-cancer"> Raise Money for Kids with Cancer </a>`
  }

};

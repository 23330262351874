export const FOOTER_SEO_CONTENT_HOME = {
  mainTitle: 'Know more about Ketto Crowdfunding Platform',
  sections: [
    {
      id: 1,
      heading: ' Are you looking to Start A Crowdfunding Campaign?',
      subHeading: 'Let Ketto Help You!',
      desc: 'Crowdfunding on <a href="https://www.ketto.org/"> Ketto </a> is free, with a <a href="https://www.ketto.org/support/plans-and-pricing.php"> 0% platform fee</a>. The 0% platform fee option empowers you to receive maximum funds for your crowdfunding campaign.',
      children: null
    },
    {
      id: 2,
      heading: '',
      subHeading: 'On our Online Crowdfunding Platform, you can raise funds for:',
      desc: 'Medical fundraisers such as cancer treatment, Kidney Failure, Heart Diseases & SMA (Spinal Muscular Atrophy). You can also raise funds to support NGO’s working towards NGO Fundraisers like Child Rights, Poverty, Environment Conservation, Women Empowerment, Care for elderly & Human Rights. During natural calamities, individuals and organisations can start Disaster Relief Fundraisers.',
      children: null
    },
    {
      id: 3,
      heading: '',
      subHeading: 'Ketto is a Crowdfunding Platform based out of India',
      desc: 'Ketto is a class apart at online crowdfunding in India. We have assisted numerous individuals, entrepreneurs and NGO’s to raise funds and their Successful Crowdfunding Stories are listed on our <a href="https://www.ketto.org/new/success-stories">Crowdfunding Success Stories Page</a>. We are pioneers of Crowdfunding Websites In India.',
      children: null
    },
    {
      id: 4,
      heading: '',
      subHeading: 'When Was Ketto Founded?',
      desc: ' Ketto is India’s best <a href="https://www.ketto.org/cause/medical-fundraising">medical crowdfunding platform</a>. Founded in 2012 by Varun Sheth, Kunal Kapoor and Zaheer Adenwala, we have assisted more than 2 Lakh individuals and Organisations in raising funds through Online Crowdfunding Campaigns across India. Ketto is India\'s First peer-to-peer crowdfunding platform. And the word KETTO (Pronounced - “Kay-toe”) stands for “Key to Tomorrow”. ',
      children: null
    },
    {
      id: 5,
      heading: '',
      subHeading: 'What is Ketto’s Mission!',
      desc: 'Our mission is to connect people to access healthcare and share happiness!',
      children: null
    },
    {
      id: 6,
      heading: '',
      subHeading: 'What is Ketto’s Vision?',
      desc: 'Ketto’s vision for the future is “Healthcare for all”. We have not limited ourselves to only medical fundraisers or NGO fundraisers and disaster relief fundraisers campaigns.',
      children: null
    },
    {
      id: 7,
      heading: '',
      subHeading: 'What is Health Nest and SIP?',
      desc: 'Recently we launched Health Nest app to cater to and bridge the growing gap for a dedicated mental healthcare and wellness community. Similarly, with <a href="https://www.ketto.org/new/sip?utm_source=internal_Ketto&utm_term=footer_seo_home">SIP (SOCIAL IMPACT PLAN)</a>, we are on a mission to make healthcare a reality for everyone, no matter who you are.',
      children: null
    },
    {
      id: 7,
      heading: '',
      subHeading: 'What can Ketto’s Crowdfunding Platform Be Used For?',
      children: [
        {
          id: 1,
          heading: 'Crowdfunding For Medical Causes',
          desc: 'Individuals, family members and patients can start a fundraising campaign for funds needed during premature births, pay for cancer treatments, fund organ transplant operations, aid in raising money to assist thalassemia patients, accidents or even acid attack victims.',
          list: null
        },
        {
          id: 2,
          heading: 'How to Start A Crowdfunding Campaign?',
          desc: 'Starting a free <a href="https://www.ketto.org/cause/medical-fundraising">crowdfunding campaign for medical causes</a>  is as simplified and easy as 1, 2, 3. ',
          list: [
            { id: 1, title: 'Start your fundraiser', desc: 'Click on Start A Fundraiser for free. Share a few details about you and the ones you are raising funds for.' },
            { id: 2, title: 'Share your fundraiser', desc: 'Share the fundraiser with your friends and family. In no time, support will start pouring in.' },
            { id: 3, title: 'Withdraw Funds', desc: 'Funds raised can be withdrawn without any hassle directly to your bank account.' }
          ]
        },
        {
          id: 3,
          heading: 'Crowdfunding For NGOs',
          desc: ' By fundraising on our online crowdfunding platform, <a href="https://www.ketto.org/how_it_works/fundraising-ideas-for-ngos.php">NGOs</a> can reach out to our dedicated followers and donors through social media. This allows NGOs to gather more funds by receiving <a href="https://www.ketto.org/crowdfunding/fundraisers">donations</a> from donors within India and other international countries. It also provides NGO’s a platform to show their support for the causes they care most about.',
          list: null
        },
        {
          id: 4,
          heading: 'Crowdfunding to Raise Memorial Funds',
          desc: 'A memorial fund is a convenient way to pay homage and assist the deceased’s family in paying expensive funeral costs. Funeral costs can add burdens to an already grieving family. By starting a memorial fundraiser on Ketto’s fundraising platform, loved ones can help the grieving family manage expenses.',
          list: null
        }
      ],
    },
    {
      id: 8,
      heading: '',
      subHeading: 'What are the Benefits Of Crowdfunding?',
      desc: 'Let’s take a look at the various benefits available to Donors and Campaigners on Ketto’s <a href="https://www.ketto.org/">online crowdfunding platform:',
      children: [
        {
          id: 1,
          heading: '0% platform fee:',
          desc: 'On Ketto’s 8th Anniversary, we introduced India\'s first 0% Platform Fee Fundraising option. This ensures more funds for you.',
          list: null
        },
        {
          id: 2,
          heading: 'Payout:',
          desc: 'It is your money, and you can withdraw it at any point during your fundraiser campaign.',
          list: null
        },
        {
          id: 3,
          heading: 'Personalized Web App:',
          desc: 'Receive instant updates of fundraiser\'s progress via alerts, email and track it real-time on Ketto\'s web app.',
          list: null
        },
        {
          id: 4,
          heading: '24/7 support:',
          desc: 'Ketto offers every campaigner 24*7 assistance via call, WhatsApp, Email, SMS and our Instant Chat Interface.',
          list: null
        },
        {
          id: 5,
          heading: 'International payment support:',
          desc: 'We accept donations in multiple currencies from any location in the world.',
          list: null
        },
        {
          id: 6,
          heading: 'Dedicated Fundraiser expert:',
          desc: 'A dedicated fundraiser expert is appointed; they will guide you throughout your <a href="https://www.ketto.org/crowdfunding">fundraising campaign</a> ',
          list: null
        },
        {
          id: 7,
          heading: 'Advertising support:',
          desc: 'Ketto provides marketing and promotional support for your fundraiser.',
          list: null
        },
        {
          id: 8,
          heading: 'Multiple people - Same Fundraiser:',
          desc: 'Multiple people manage and fundraise for your cause.',
          list: null
        },
        {
          id: 9,
          heading: 'Keep the raised amount:',
          desc: 'You will receive all the raised amounts after the transactional processing fee.',
          list: null
        },
        {
          id: 10,
          heading: 'Keep the raised amount:',
          desc: 'You will receive all the raised amounts after the transactional processing fee.',
          list: null
        },
        {
          id: 11,
          heading: 'Fundraising Marketing tool:',
          desc: 'A brilliant marketing tool, which provides all insights on your fundraiser.',
          list: null
        },
        {
          id: 12,
          heading: 'No Payback:',
          desc: 'Funds raised via your <a href="https://www.ketto.org/crowdfunding">crowdfunding campaign</a> need not be repaid. Contrary to Bank Loans, fundraising allows the beneficiary to keep the money raised to yourself to assist in post-hospitalization expenditure.',
          list: null
        },
      ]
    },
    {
      id: 9,
      heading: '',
      subHeading: 'Trust & Safety',
      desc: 'At Ketto, we ensure complete authenticity and transparency. We strive to ensure the fundraising process is seamless, positive and secure.',
      children: null
    }
  ],
  qs: [
    { id: 1, title: 'Will I get the money I raised from my campaign?', },
    { id: 2, title: 'Will my donation reach the cause?' },
    { id: 3, title: 'Will my money get lost in cyberspace?' },
  ],
  as: [
    { id: 1, title: 'This is your hard-earned money; we know you are asking the right questions.' },
    { id: 2, title: 'Don’t worry; we are here to answer your questions! ' },
    { id: 3, title: 'Visit our <a href="https://customerhappiness.ketto.org/portal/en/kb/articles/trust-safety-7-8-2021">Trust & Safety</a> page to find answers to all your queries. ' }
  ],
  searches: {
    data: `<a href="https://www.ketto.org/cause/cancer-crowdfunding">Fundraising for Cancer</a> |
        <a href="https://www.ketto.org/cause/medical-fundraising">Medical Fundraising</a> |
        <a href="https://www.ketto.org/fundraiser/fundakidneytransplant">Fundraising for Transplant</a> |
        <a href="https://www.ketto.org/crowdfunding">How Crowdfunding Works</a> |
        <a href="https://www.ketto.org/crowdfunding/fundraisers"> Raise a Fund</a> |
        <a href="https://www.ketto.org/">Crowdfunding Platform</a> |
        <a href="https://covid19.ketto.org/">Coronavirus Relief Fund</a> |
        <a href="https://www.ketto.org/fundraiser/ppe-kits-for-ngo">Coronavirus (COVID-19) fundraising for PPE Kits</a> |
        <a href="https://www.ketto.org/campaign/crowdfunding-for-education.php"> Education Crowdfunding</a> |
        <a href="https://www.ketto.org/how_it_works/fundraising-ideas-for-ngos.php">NGO Fundraising</a> |
        <a href="https://www.ketto.org/blog/social-impact-plan-from-ketto-is-taking-fundraising-to-the-next-level"> Monthly Donation Plan (SIP)</a> |
        <a href="https://www.ketto.org/blog/what-is-health-nest">What Is Health Nest?</a> |
        <a href="https://www.ketto.org/blog/how-can-i-raise-money-for-surgery"> Raise Money for Surgery</a> |
            <a href="https://www.ketto.org/blog/how-to-start-crowdfunding-for-animal-rescue"> Crowdfunding for Animal Rescue</a> |
            <a href="https://www.ketto.org/blog/how-can-i-raise-money-for-charity">Raise Money for Charity</a> |
            <a href="https://www.ketto.org/blog/how-medical-crowdfunding-works">How Medical Crowdfunding Works</a> |
            <a href="https://www.ketto.org/blog/are-campaigns-in-ketto-genuine">Are Campaigns in Ketto Genuine?</a> |
            <a href="https://www.ketto.org/blog/crowdfunding-and-tax-deduction"> Crowdfunding and Tax Deduction</a> |
            <a href="https://ketto.blog/blog/five-quick-fundraising-ideas">Five Quick Fundraising Ideas</a> |
            <a href="https://ketto.blog/blog/5-innovative-online-fundraising-ideas-for-non-profit-organization">Fundraising Ideas for Non- Profit Organization</a> |
            <a href="https://ketto.blog/blog/what-is-sports-crowdfunding"> What is Sports Crowdfunding?</a>`
  }
};

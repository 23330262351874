import { Injectable } from '@angular/core';

// declare let paymentV2AB;
declare let story_one_tap_AB;
declare let slab_AB;
declare let repeatorder_ministoryAB;
declare let thankyou_standard_AB;
declare let payu_AB;
declare let bulk_donation_hf_mini_AB;
declare let dm_AB;
declare let quickDonate_fallback_p1_AB;
declare let quickDonate_fr_AB;
declare let kcart_fr_urgent_tag_AB;
declare let story_variation_5;
declare let tip_desktop_story_AB;
declare let kcart_tip_story_AB; // (vwo.kcart_tip_story_AB() && vars.showKCart) remove in tip comp after result
declare let kcart_slab_AB;
declare let kcart_fund_story_pay_AB;

@Injectable({
  providedIn: 'root'
})
export class VwoService {

  constructor() { }

  // paymentV2AB() {
  //   if (typeof paymentV2AB !== 'undefined') {
  //     return paymentV2AB;
  //   }
  // }

  story_one_tap_AB() {
    if (typeof story_one_tap_AB !== 'undefined') {
      return story_one_tap_AB;
    }
  }

  slab_AB() {
    if (typeof slab_AB !== 'undefined') {
      return slab_AB;
    }
    // return 'non_inr_ab'
  }

  repeatorder_ministoryAB() {
    if (typeof repeatorder_ministoryAB !== 'undefined') {
      return repeatorder_ministoryAB;
    }
  }

  thankyou_standard_AB() {
    if (typeof thankyou_standard_AB !== 'undefined') {
      return thankyou_standard_AB;
    }
  }

  payu_AB() {
    if (typeof payu_AB !== 'undefined') {
      return payu_AB;
    }
  }

  bulk_donation_hf_mini_AB() {
    if (typeof bulk_donation_hf_mini_AB !== 'undefined') {
      return bulk_donation_hf_mini_AB;
    }
  }

  dm_AB() {
    if (typeof dm_AB !== 'undefined') {
      return dm_AB;
    }
  }

  quickDonate_fallback_p1_AB() {
    if (typeof quickDonate_fallback_p1_AB !== 'undefined') {
      return quickDonate_fallback_p1_AB;
    }
  }

  quickDonate_fr_AB() {
    if (typeof quickDonate_fr_AB !== 'undefined') {
      return quickDonate_fr_AB;
    }
  }

  kcart_fr_urgent_tag_AB() {
    if (typeof kcart_fr_urgent_tag_AB !== 'undefined') {
      return kcart_fr_urgent_tag_AB;
    }
  }

  story_variation_5() {
    if (typeof story_variation_5 !== 'undefined') {
      return story_variation_5;
    }
  }

  tip_desktop_story_AB() {
    if (typeof tip_desktop_story_AB !== 'undefined') {
      return tip_desktop_story_AB;
    }
  }

  kcart_tip_story_AB() {
    if (typeof kcart_tip_story_AB !== 'undefined') {
      return kcart_tip_story_AB;
    }
  }

  kcart_slab_AB() {
    if (typeof kcart_slab_AB !== 'undefined') {
      return kcart_slab_AB;
    }
  }

  kcart_fund_story_pay_AB() {
    if (typeof kcart_fund_story_pay_AB !== 'undefined') {
      return kcart_fund_story_pay_AB;
    }
  }

}

export const FOOTER_SEO_CONTENT_ANIMAL_FUNDRAISING = {
  mainTitle: 'Crowdfunding for Animals Made Easy. Click Here to Know More',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'What is Animal Fundraising?',
      desc: `<p>When you look at an animal on the road wrapped up in the garbage and lacking primary sanitization/care and love, does your heart hurt? Most of us feel incredibly protective and consider our pets beloved family members. When they get sick or hurt and need immediate veterinary care, you want to offer them the best care possible.</p>
            <p>Unfortunately, veterinary care is costly, and you could be burdened with the vet bill if your pet or stray suffers a health emergency. </p>`,
      children: null
    },
    {
      id: 2,
      heading: '',
      subHeading: 'This is where Ketto comes to your aid!',
      desc: `<p>We at Ketto help you raise funds online for vet bills and other pet expenses through animal fundraising. All you need to do is <a href="https://www.ketto.org/new/crowdfunding">START AN ANIMAL FUNDRAISING</a> page with photos and information about your beloved pet and then share it with your social network to ask for help.</p><p>Now you can raise funds from your family friends and network with crowdfunding campaigns on Ketto’s online crowdfunding platform:</p>`,
      children: null
    },
    {
      id: 3,
      heading: '',
      subHeading: 'Below are some pressing causes that need immediate Crowdfunding.',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'Crowdfunding For Animal Shelters',
          desc: `<p>Every city’s local shelter needs more help to house and care for the friendly stray animals of the neighbourhood.</p><p>Sign up on Ketto and start a crowdfunding campaign to raise money to help these local animal shelters.</p>`,
          list: null
        },
        {
          id: 2,
          heading: 'Cost of surgery for animals',
          desc: `<p>There are countless sick pet’s and stray animals waiting to undergo life-saving surgeries. However, since these surgeries are expensive, they have to suffer until enough funds have been gathered; this waiting is excruciating for the animals and pets.</p><p>By starting an online campaign to support the operation costs, you can help reduce these animals pains and suffering.
                    </p>`,
          list: null
        }
      ]
    },
    {
      id: 4,
      heading: 'Why must you fundraise for animals?',
      subHeading: '',
      desc: `<p>As the saying goes, “The greatness of a nation and its moral progress can only be judged by the way its animals are treated.” – Mahatma Gandhi.</p><p>Humans share this universe with many creatures who depend on us for many things. The world has limited natural resources, and the human population continues to increase, with many of the world’s poorest, relying on animals as a source for their income and livelihood. </p>`,
      children: null
    },
    {
      id: 5,
      heading: '',
      subHeading: 'We have outlined a few reasons for you:',
      desc: ``,
      children: [
        {
          id: 1,
          heading: '',
          description: '',
          bullet: true,
          list: [
            {
              id: 1,
              title: 'Families around the globe are reliant on working animals',
              desc: ''
            },
            {
              id: 2,
              title: 'Animals provide four-legged love, care and support for the disabled',
              desc: ''
            },
            {
              id: 3,
              title: 'Animals are proven to improve human health',
              desc: ''
            },
            {
              id: 4,
              title: 'Shelters help reduce the number of strays and offer pets',
              desc: ''
            },
            {
              id: 5,
              title: 'Animals make excellent companions to humans',
              desc: ''
            }
          ]
        }
      ]
    },
    {
      id: 6,
      heading: '',
      subHeading: 'Different types of Fundraisers for Animals?',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'One can raise funds for the following:',
          description: '',
          bullet: true,
          list: [
            {
              id: 1,
              title: 'Raise Funds for Sterilization Programmes of animals',
              desc: ''
            },
            {
              id: 2,
              title: 'Raise Funds for Medical Treatments of animals',
              desc: ''
            },
            {
              id: 3,
              title: 'Start fundraising to Sponsor Meals at animal shelters',
              desc: ''
            },
            {
              id: 4,
              title: `<p>Raise funding to build better <a href="https://www.ketto.org/blog/animal-shelter-fundraising-ideas">Animal Shelters</a>`,
              desc: ''
            },
            {
              id: 5,
              title: 'Ask for donations to Make Documentaries to educate the masses',
              desc: ''
            },
            {
              id: 6,
              title: 'Raise Money For Your Pet',
              desc: ''
            }
          ]
        }
      ]
    }
  ],
  searches: {
    data: `<a href="https://www.ketto.org/new/sip?utm_source=internal_Ketto&utm_term=footer_seo_animals">Social Impact Plan</a> |
        <a href="https://www.ketto.org/blog/what-is-health-nest">What Is Health Nest?</a> |
        <a href="https://www.ketto.org/blog/cancer-crowdfunding-5-ways-to-raise-money-for-kids-with-cancer">Raise Money for Kids with Cancer</a> |
        <a href="https://www.ketto.org/cause/fundraising-for-education">Education Crowdfundings</a> |
        <a href="https://www.ketto.org/how_it_works/fundraising-ideas-for-ngos.php"> NGO Fundraising</a> |
        <a href="https://www.ketto.org/blog/how-to-start-crowdfunding-for-animal-rescue">Crowdfunding for Animal Rescue</a>`
  }
};

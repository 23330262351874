import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EventsService } from '../events/events.service';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { CookieService } from '../cookie/cookie.service';
import { VariablesService } from '../variables/variables.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from '../util/util.service';
import { PaymentService } from '../payment/payment.service';

declare let Pusher;

interface IPusher {
  transaction_return_status?: string;
  reference_number?: string;
  amount?: number;
  pg_used?: string;
  event?: string;
  order_id?: string;
  channel?: string;
  failue_reason?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusherChannel = null;
  sendOrderId;
  channelName;
  vpaInterval;

  constructor(
    private scriptLoaderService: ScriptLoaderService,
    public cookieService: CookieService,
    public vars: VariablesService,
    private router: Router,
    private dialog: MatDialog,
    private eventsService: EventsService,
    private util: UtilService,
    private paymentService: PaymentService
  ) { }

  /**
 * Create the event of the pusher, there two scenraio
 * first load the script of the pusher
 * second if puhser event is not created then create it
 * otherwise return already existing pusher
 * @param channelId Event name of the pusher object
 */
  pusherTruecallerObj = {
    subscribe: (channelId?) => {
      return new Promise((resolve, reject) => {
        try {
          const channel = this.vars.req_nonce.toString();  // Add Request Nonce | only string is accepted as Channel Id
          if (typeof Pusher !== 'undefined') {
            if (!this.pusherChannel) {
              this.createChannel(channel);
            } else if (this.pusherChannel.name !== channel) {
              this.createChannel(channel);
            }
            resolve(this.pusherChannel);
          } else {
            this.scriptLoaderService.load('pusher').then(() => {
              resolve(this.pusherTruecallerObj.subscribe(channelId));
            });
          }
        } catch (error) {
          reject(false);
        }
      });
    }
  };

  /**Create the pusher channel */
  createChannel(channel) {
    const pusher = new Pusher(environment.puhser_key, {
      cluster: 'ap1',
      forceTLS: true
    });
    this.pusherChannel = pusher.subscribe(channel);
  }

  /**Wait for the pusher response then triger */
  async pusher(channel, type?) {
    this.channelName = channel;
    if (type === 'truecaller') {
      const pusherChannel: any = await this.pusherTruecallerObj.subscribe();
      pusherChannel.bind(channel, (event) => { });
    }
  }

  /** Pusher Service for upi tranctions */
  async activateUPIPusher(order) {
    if (order?.payment_gateway === 'phonepe' && order?.payment_mode === 'UPI') {
      return;
    }
    await this.scriptLoaderService.load('pusher');
    if (typeof Pusher !== 'undefined') {
      const pusher = new Pusher(environment.puhser_key, {
        cluster: 'ap1',
        forceTLS: true
      });
      pusher.subscribe(order?.order_id).bind('upitransaction_ord', (event: IPusher) => {
        if (event?.transaction_return_status == '200') {
          this.redirectAfterPayment(event)
        } else {
          if (event?.failue_reason) {
            this.util.openSnackBar(event.failue_reason, 'error');
            this.vars.upiTimer = false;
          }
        }
      });
    } else {
      this.activateUPIPusher(order);
    }
  }

  private redirectAfterPayment(event) {
    this.vars.upiTimer = false;
    this.eventsService.sendSystemEvent({
      eventName: 'pusher_response',
      event_type: 'payment',
      page_name: this.vars.pageName,
      info_2: event && event.order_id ? event.order_id : this.vars.currentOrderId,
      info_4: event ? JSON.stringify(event) : '',
    }).subscribe(_ => _);

    this.router.navigate(['/new/thankyou'], {
      queryParams: { ...this.vars.tyParams }
    }).then(() => {
      this.dialog.closeAll();
    });
  }

  vpaTxnStatus(data: { order_id: string, origin: string }) {
    let stop_pending_loop = false;
    if (this.vpaInterval) {
      clearInterval(this.vpaInterval);
    }
    this.vars.upi_payment_status.subscribe(s => { if (s === 'canceled') { this.clearUpiStatus(); } });
    this.vpaInterval = setInterval(() => {
      this.paymentService.vpaTxnStatus(data).subscribe((resp: any) => {
        if (resp?.data?.status?.toLocaleLowerCase() === 'success') {
          this.vars.upi_payment_status.next('success');
          this.clearUpiStatus();
        } else if (resp?.data?.status?.toLocaleLowerCase() === 'failed') {
          this.util.openSnackBar(resp.data.message, 'error');
          this.vars.upi_payment_status.next('failed');
          this.clearUpiStatus();
        } else if (resp?.data?.status?.toLocaleLowerCase() === 'pending' && !stop_pending_loop) {
          stop_pending_loop = true;
          setTimeout(() => {
            if (this.vars.upi_payment_status.value && !this.vars.upi_payment_status.value.match('success|failed')) {
              this.clearUpiStatus();
              this.util.openSnackBar('Payment failed or pending, please check bank status', 'error');
              this.vars.upi_payment_status.next('failed');
            }
          }, 180000);
        }
      }, err => { console.log(err) });
    }, 5000);
  }

  clearUpiStatus() {
    if (this.vpaInterval) {
      clearInterval(this.vpaInterval);
    }
    if (this.vars.upi_payment_status.value !== '') {
      this.vars.upi_payment_status.next('');
    }
    this.vars.upiTimer = false;
  }

}

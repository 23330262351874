export const FOOTER_SEO_CONTENT_CHILD_HELP = {
  mainTitle: 'Raise funds to help children! Click Here to Know More',
  sections: [
    {
      id: 1,
      heading: '',
      subHeading: 'Did you Know?',
      desc: 'The Child Rights and You (CRY) census report says 1 out of 4 children don\'t receive schooling, more than 19 million are malnourished, 10 million are oppressed through child labour, and more than 11 million are on the streets. <br><br> Crowdfunding online using Ketto\'s online crowdfunding platform can be the change and make a huge impact to reduce these numbers. Fundraising online can help you raise money online from donors, supporters and well-wishers to offer a child an inspiring living.',
      children: null
    },
    {
      id: 2,
      heading: '',
      subHeading: 'Importance of Children',
      desc: `<p>Children are God's gift to us and are looked upon as a blessing in Indian society and families! Keeping children healthy, safe and well provided for is a cause we can all unite and help selflessly. After all, there's nothing more rewarding than helping the little ones we care for. </p>
            <p>Hence, we urge every one of you reading this to Start Crowdfunding For Child Welfare and begin to donate towards a children's fundraiser on Ketto's online crowdfunding platform.</p>
            <p>Here's why you should choose Kettos online crowdfunding platform to raise funds for child welfare!</p>
            <p>You can start a crowdfunding campaign for child Welfare, donate for children, children fund on Ketto's platform within minutes for free and quickly raise funds at your fingertips with the help of a few clicks.</p>`,
      children: null
    },
    {
      id: 3,
      heading: '',
      subHeading: 'Help is now just a share away. Kudos!',
      desc: `<p>Social media platforms make the funding process super easy and quick. Share your child welfare fundraising campaign over Emails, WhatsApp other social media accounts and get funds from contributors, supporters and well-wishers directly.</p>
            <p>Bring your cause to everyone's notice to spread awareness. Increase the list of your supporters and contributors and get them involved in the children fund activity.</p>`,
      children: null
    },
    {
      id: 4,
      heading: '',
      subHeading: 'What can you raise funds for?',
      desc: '',
      children: null
    },
    {
      id: 5,
      heading: '',
      subHeading: 'Ketto\'s online crowdfunding and fundraising platform allow you to raise funds for:',
      desc: '',
      children: [
        {
          id: 1,
          heading: 'Child\'s Medical Treatments',
          desc: `<p>Seeing your child in pain makes your blood curdle. Medical treatment costs at private hospitals are sure to burn holes in the common middle-class families pockets. Bank loans are hardly ever approved on time, and they can cause complications to the already financially burdened family.</p>
                    <p>On Ketto, you can Start an Online Campaign to raise funds to pay for your child's medical treatments.</p>`,
          list: null
        },
        {
          id: 2,
          heading: 'Child Education',
          desc: `<p>Parents want to ensure their children study in the best institutions. Schooling and college expenses are getting costlier as the years pass by. The education you can provide today at a minimal cost would cost 50-100 times more when your child reaches college.</p>
                    <p>No parent wants to deny their child from receiving the best college or university education.</p>
                    <p>With KETTO education crowdfunding, you and your child can begin a crowdfunding campaign and receive funds to continue education and even apply to foreign universities.</p>`,
          list: null
        },
        {
          id: 3,
          heading: 'Supports Child Athletes',
          desc: `<p>Does your child run faster than the other kids? Do you feel they can become the next gold medalist at school, college, university, or even the Olympics? </p>
                    <p>Sports equipment is expensive, and securing loans or scholarships for athletics is challenging and next to impossible. You can ensure your child has all the support they need to excel at sports. Start <a href="https://www.ketto.org/new/crowdfunding">raising funds</a> for your child's sports career today with Ketto.</p>
                    <p><strong><i>It is never too late to brighten a child's future! Start a Child Welfare Campaign on Ketto to raise funds today to better the lives of children!</i></strong></p>`,
          list: null
        }
      ]
    },
  ],
  searches: {
    data: `<a href="https://www.ketto.org/new/sip?utm_source=internal_Ketto&utm_term=footer_seo_child">Social Impact Plan</a> |
        <a href="https://www.ketto.org/blog/what-is-health-nest">What Is Health Nest?</a> |
        <a href="https://www.ketto.org/blog/cancer-crowdfunding-5-ways-to-raise-money-for-kids-with-cancer">Raise Money for Kids with Cancer</a> |
        <a href="https://www.ketto.org/cause/fundraising-for-education">Education Crowdfunding</a> |
        <a href="https://www.ketto.org/how_it_works/fundraising-ideas-for-ngos.php"> NGO Fundraising</a>`
  }
};
